<template>
  <span data-name="sort-by"
    role="status"
    :aria-label="direction === 1 ? 'Sorted ascending': direction === 0 ? 'Not sorted': 'Sorted descending'">
    <i v-if="direction === 1"
      class="fa fa-sort-up"
      aria-hidden="true" />
    <i v-if="direction === 0"
      class="fa fa-sort"
      aria-hidden="true" />
    <i v-if="direction === -1"
      class="fa fa-sort-down"
      aria-hidden="true" />
  </span>
</template>

<script>
export default {
  name: 'TableSortButtons',
  props: {
    direction: {
      type: Number,
      required: false,
      default: () => {
        return 0
      },
    },
  },
}
</script>

<style scoped>
.fa-sort {
  color: #cccccc;
}
</style>
