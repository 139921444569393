<template>
  <section data-name="peer-routes-view"
    data-testid="peer-routes-view"
    class="el-row pt-1">
    <p v-if="isLoading"
      id="loading"
      v-loading="true"
      data-testid="loading-wrapper"
      class="pt-4 pb-4"
      :element-loading-text="$t('mcr-looking-glass.loading')"
      element-loading-background="rgba(0, 0, 0, 0.0)"
      role="status" />

    <template v-else-if="hasErrors">
      <el-alert v-for="(loadingError, $index) in loadingErrors"
        :id="`loadingError-${ $index }`"
        :key="$index"
        class="mt-1"
        :data-testid="`loading-error-${ $index }`"
        :title="$t('mcr-looking-glass.service-could-not-be-loaded', { errorStatus: loadingError.status })"
        type="info"
        :description="loadingError.message"
        :closable="false"
        show-icon />
    </template>

    <template v-else>
      <peer-routes-filter :text-filter="textFilter"
        :type-filter="typeFilter"
        :routes="{ advertisedRoutes, receivedRoutes }"
        @updateFilter="handleUpdateFilter" />
      <routes-table :table-items="routesFiltered"
        data-testid="routes-table"
        :schema="schema"
        :sort-by="sortBy"
        :sort-direction="sortDirection"
        class="ml-1 mr-1"
        @updateSortOrder="handleUpdateSortOrder" />
    </template>
  </section>
</template>

<script>
import PeerRoutesFilter from './PeerRoutesFilter'
import RoutesTable from './../common/RoutesTable'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PeerRoutesView',

  components: {
    'peer-routes-filter': PeerRoutesFilter,
    'routes-table': RoutesTable,
  },

  props: {
    ipAddress: {
      type: String,
      required: true,
      default: () => {
        return ''
      },
    },
  },

  data() {
    return {
      sortBy: 'destination',
      sortDirection: -1,
      textFilter: '',
      typeFilter: 'advertised',
    }
  },

  computed: {
    ...mapGetters('MegaportCloudRouter', ['isLoadingBGPNeighbourRoutes', 'getSchema', 'getBGPNeighbourRoutes', 'getBGPNeighbourRouteErrors']),
    schema() {
      return this.getSchema('bgp')
    },
    isLoading() {
      return this.isLoadingBGPNeighbourRoutes(this.ipAddress)
    },
    routes() {
      return this.getBGPNeighbourRoutes(this.ipAddress)
    },
    activatedRoutes() {
      return this.routes[this.typeFilter]
    },
    routesSorted() {
      return this.activatedRoutes.slice().sort((a, b) => {
        if (a[this.sortBy] < b[this.sortBy]) return 1 * this.sortDirection
        if (a[this.sortBy] > b[this.sortBy]) return -1 * this.sortDirection
        return 0
      })
    },
    routesFiltered() {
      return this.routesSorted.filter(route => {
        return JSON.stringify(route)
          .toLowerCase()
          .includes(this.textFilter.toLowerCase())
      })
    },
    hasErrors() {
      return this.loadingErrors?.length > 0
    },
    loadingErrors() {
      return this.getBGPNeighbourRouteErrors(this.ipAddress)
    },
    advertisedRoutes() {
      return this.routes['advertised']
    },
    receivedRoutes() {
      return this.routes['received']
    },
  },

  async mounted() {
    await this.fetchBGPNeighbourRoutes({ productUid: this.$route.params.mcr, ipAddress: this.ipAddress })
  },

  methods: {
    ...mapActions('MegaportCloudRouter', ['fetchBGPNeighbourRoutes']),
    handleUpdateSortOrder(target) {
      if (target !== this.sortBy) {
        this.sortBy = target
        this.sortDirection = -1
      } else {
        this.sortDirection *= -1
      }
    },
    handleUpdateFilter(filterObj) {
      this[filterObj.key] = filterObj.value
    },
  },
}
</script>
