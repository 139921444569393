<template>
  <el-form data-name="routes-table-filter"
    role="navigation"
    class="el-row"
    @submit.native.prevent>
    <el-form-item class="el-col-10">
      <strong class="pr-1"
        role="label">{{ $t('general.show') }}</strong>
      <el-button-group>
        <el-button data-name="advertised-btn"
          :type="typeFilter === 'advertised' ? 'primary' : 'default'"
          size="mini"
          @click="handleTypeChange('advertised')">
          {{ $t('mcr-looking-glass.advertised') }} ({{ routes.advertisedRoutes.length }})
        </el-button>
        <el-button data-name="received-btn"
          :type="typeFilter === 'received' ? 'primary' : 'default'"
          size="mini"
          @click="handleTypeChange('received')">
          {{ $t('mcr-looking-glass.received') }} ({{ routes.receivedRoutes.length }})
        </el-button>
      </el-button-group>
    </el-form-item>
    <el-form-item id="filterText"
      class="el-col-8">
      <el-input size="mini"
        :placeholder="$t('mcr-looking-glass.filter-routes')"
        :value="textFilter"
        @input="handleTextChange">
        <template #append>
          <el-button size="mini"
            @click="clearTextFilter">
            <i class="fa fa-times-circle"
              label="Clear filter routes"
              aria-hidden="true" />
          </el-button>
        </template>
      </el-input>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'RoutesTableFilter',

  props: {
    textFilter: {
      type: String,
      required: false,
      default: () => {
        return ''
      },
    },
    typeFilter: {
      type: String,
      required: false,
      default: () => {
        return 'all'
      },
    },
    routes: {
      type: Object,
      required: false,
      default: () => {
        return {
          advertisedRoutes: [],
          receivedRoutes: [],
        }
      },
    },
  },

  methods: {
    handleTextChange(value) {
      this.$emit('updateFilter', { key: 'textFilter', value: value })
    },
    handleTypeChange(value) {
      this.$emit('updateFilter', { key: 'typeFilter', value: value })
    },
    clearTextFilter() {
      this.handleTextChange('')
    },
  },
}
</script>

<style scoped>
#filterText .el-input {
  vertical-align: baseline;
}
</style>
