<template>
  <section data-name="all-routes-view"
    class="el-row pt-2">
    <!-- All routes / Routes by IP toggle -->
    <div class="el-row ml-1 mr-1 pb-2 mb-1"
      role="none">
      <el-radio-group v-model="activeDataView"
        class="dataViewToggle el-col-lg-10 el-col-md-24 pb-1"
        @change="resetFilter">
        <el-radio label="allRoutes">
          {{ $t('mcr-looking-glass.all-routes') }} ({{ routesSorted.length }})
        </el-radio>
        <el-radio label="ipRoutes">
          {{ $t('mcr-looking-glass.routes-by-ip') }}
          <template v-if="routesByIPSorted.length">
            ({{ routesByIPSorted.length }})
          </template>
        </el-radio>
      </el-radio-group>

      <form v-show="activeDataView === 'ipRoutes'"
        data-view="ipRoutesInput"
        class="el-col-lg-14 el-col-md-24 flex-row-centered flex-wrap"
        role="search"
        @submit.prevent="findRoutes">
        <network-address-input v-bind="networkInputConfig"
          class="mr-1"
          @updateNetAddress="handleUpdateNetAddress" />
        <el-button type="primary"
          size="mini"
          plain
          @click="findRoutes">
          {{ $t('mcr-looking-glass.find') }}
        </el-button>
      </form>
    </div>

    <!-- Table filter -->
    <div class="el-row ml-1 mr-1"
      role="menubar">
      <text-search-input :text-filter="textFilter"
        class="el-col-sm-24 el-col-md-24 el-col-lg-10"
        @updateFilter="handleUpdateFilter" />
      <routes-table-filter v-if="schema.filters.length"
        :filter-list="schema.filters"
        :label="schema.filterLabel"
        :type-filter="typeFilter"
        class="el-col-sm-24 el-col-md-24 el-col-lg-14 pb-1 pl-1 text-align-right"
        @updateFilter="handleUpdateFilter" />
    </div>

    <hr class="mt-1 pt-1">

    <div v-if="isLoading"
      v-loading="isLoading"
      class="loading"
      :element-loading-text="$t('mcr-looking-glass.loading')"
      element-loading-background="rgba(0, 0, 0, 0.0)"
      role="alert" />

    <!-- All routes -->
    <template v-else-if="activeDataView === 'allRoutes'">
      <routes-table v-if="schema.displayAs === 'table' && !loadingError"
        :table-items="routesFiltered"
        :schema="schema"
        :sort-by="sortBy"
        :sort-direction="sortDirection"
        class="ml-1 mr-1"
        @updateSortOrder="handleUpdateSortOrder" />
      <el-alert v-else
        data-name="no-service"
        :title="$t('mcr-looking-glass.service-could-not-be-loaded', { errorStatus: loadingError.status })"
        type="info"
        :description="loadingError.status >= 500 && loadingError.status <= 599
          ? ''
          : loadingError.message"
        :closable="false"
        show-icon />
    </template>

    <!-- Routes by IP -->
    <template v-else-if="activeDataView === 'ipRoutes'">
      <p v-if="routesByIP === null"
        class="pt-1 ml-1"
        data-name="ip-routes-waiting"
        role="alert">
        {{ $t('mcr-looking-glass.enter-ip') }}
      </p>

      <p v-else-if="!routesByIP.length"
        data-name="ip-routes-none"
        role="alert">
        {{ $t('mcr-looking-glass.no-routes') }}
      </p>

      <template v-else-if="routesByIP.length">
        <routes-table v-if="schema.displayAs === 'table'"
          :table-items="routesByIPFiltered"
          :schema="schema"
          :sort-by="sortBy"
          :sort-direction="sortDirection"
          class="ml-1 mr-1"
          @updateSortOrder="handleUpdateSortOrder" />
      </template>
    </template>
  </section>
</template>

<script>
import RoutesTable from './common/RoutesTable'
import RoutesTableFilter from './common/RoutesTableFilter'
import TextSearchInput from './common/TextSearchInput'
import NetworkAddressInput from './common/NetworkAddressInput'
import { mapState, mapActions, mapGetters } from 'vuex'

export default {
  name: 'MCRRoutesView',

  components: {
    'routes-table': RoutesTable,
    'routes-table-filter': RoutesTableFilter,
    'text-search-input': TextSearchInput,
    'network-address-input': NetworkAddressInput,
  },

  props: {
    productUid: {
      type: String,
      required: true,
      default: () => {
        return ''
      },
    },
    context: {
      // context switch (ip, bgp)
      type: String,
      required: true,
      default: () => {
        return 'ip'
      },
    },
  },

  data() {
    return {
      activeDataView: 'allRoutes',
      sortBy: 'destination',
      sortDirection: -1,
      textFilter: '',
      typeFilter: 'all',
      filterColumn: '',
      networkInputValue: '', // not implemented
      networkInputConfig: {
        inline: false,
      },
      findHostInputValue: {
        value: '',
      },
    }
  },

  computed: {
    ...mapState('MegaportCloudRouter', ['isLoadingIPRoutes', 'isLoadingBGPRoutes']),
    ...mapGetters('MegaportCloudRouter', ['getRoutesByIP', 'getRoutes', 'getSchema', 'getRouteErrors']),
    isLoading() {
      return this.context === 'ip' ? this.isLoadingIPRoutes : this.isLoadingBGPRoutes
    },
    loadingError() {
      return this.getRouteErrors(this.context)
    },
    routesByIP() {
      return this.getRoutesByIP(this.context)
    },
    routes() {
      return this.getRoutes(this.context)
    },
    schema() {
      return this.getSchema(this.context)
    },
    routesSorted() {
      return this.routes.slice().sort((a, b) => {
        if (a[this.sortBy] < b[this.sortBy]) return 1 * this.sortDirection
        if (a[this.sortBy] > b[this.sortBy]) return -1 * this.sortDirection
        return 0
      })
    },
    routesByType() {
      if (this.typeFilter === 'all') return this.routesSorted
      return this.routesSorted.filter(route => {
        return route[this.filterColumn].toLowerCase() === this.typeFilter.toLowerCase()
      })
    },
    routesFiltered() {
      return this.routesByType.filter(route => {
        const entries = Object.values(route)
        return JSON.stringify(entries)
          .toLowerCase()
          .includes(this.textFilter.toLowerCase())
      })
    },
    routesByIPSorted() {
      if (!this.routesByIP) return []
      return this.routesByIP.slice().sort((a, b) => {
        if (a[this.sortBy] < b[this.sortBy]) return 1 * this.sortDirection
        if (a[this.sortBy] > b[this.sortBy]) return -1 * this.sortDirection
        return 0
      })
    },
    routesByIPByType() {
      if (this.typeFilter === 'all') return this.routesByIPSorted
      return this.routesByIPSorted.filter(route => {
        return route[this.filterColumn].toLowerCase() === this.typeFilter.toLowerCase()
      })
    },
    routesByIPFiltered() {
      return this.routesByIPByType.filter(route => {
        const entries = Object.values(route)
        return JSON.stringify(entries)
          .toLowerCase()
          .includes(this.textFilter.toLowerCase())
      })
    },
  },

  mounted() {
    // fetch routes by context
    this.fetchRoutes({ productUid: this.productUid, context: this.context })
  },

  methods: {
    ...mapActions('MegaportCloudRouter', ['fetchRoutes', 'clearIPRoutes']),
    handleUpdateSortOrder(target) {
      if (target !== this.sortBy) {
        this.sortBy = target
        this.sortDirection = -1
      } else {
        this.sortDirection *= -1
      }
    },
    handleUpdateFilter(filterObj) {
      this[filterObj.key] = filterObj.value
      if (filterObj.column) this.filterColumn = filterObj.column
    },
    handleUpdateNetAddress(udpateObj) {
      this.networkInputConfig = { ...this.networkInputConfig, invalidate: false }
      this.findHostInputValue = udpateObj
      if (udpateObj.value === '') {
        this.clearIPRoutes(this.context)
      }
    },
    findRoutes() {
      if (!this.findHostInputValue.valid || !this.findHostInputValue.value.length) {
        this.networkInputConfig = {
          ...this.networkInputConfig,
          invalidate: true,
          invalidateMessage: 'Please enter a valid IP or network address',
          invalidateType: 'info',
        }
      } else {
        this.clearIPRoutes(this.context)
        this.fetchRoutes({ productUid: this.productUid, context: this.context, ipAddress: this.findHostInputValue.value })
      }
    },
    resetFilter() {
      this.typeFilter = 'all'
    },
  },
}
</script>

<style lang="scss" scoped>
.dataViewToggle {
  padding-top: 0.5rem;
}
.loading {
  height: 400px;
}
</style>
