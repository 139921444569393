<template>
  <el-form data-name="mcr-dropdown-select"
    class="p-1">
    <el-form-item>
      <template #label>
        <span class="label"
          role="none">{{ $t('productNames.mcr') }}</span>
      </template>
      <el-select :value="activeMCR.productName"
        @change="handleMCRChange">
        <el-option v-for="port in mcrList"
          :key="port.productUid"
          :label="port.productName"
          :value="port.productName" />
      </el-select>
    </el-form-item>
  </el-form>
</template>

<script>
export default {
  name: 'MCRDropdownSelect',

  props: {
    mcrList: {
      type: Array,
      required: true,
    },
    activeMCR: {
      type: Object,
      required: false,
      default: () => {
        return {}
      },
    },
  },

  computed: {
    selectedMCR() {
      return this.mcrList.find(el => el.selected)
    },
  },

  methods: {
    handleMCRChange(value) {
      const selected = this.mcrList.find(mcr => {
        return mcr.productName === value
      })
      this.$emit('update', selected)
    },
  },
}
</script>

<style scoped lang="scss">
.el-form-item {
  margin-bottom: 0;
  .label {
    font-weight: bold;
  }
  div {
    display: flex;
    .el-select {
      flex-grow: 1;
    }
  }
}
</style>
