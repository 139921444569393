<template>
  <span v-if="hasValue"
    role="none">
    <template v-if="layout.format === 'link'">
      <router-link :key="layout.data"
        :to="layout.to(item)">

        {{ item[layout.data][layout.link.value] }}
      </router-link>
    </template>

    <template v-else-if="layout.format === 'list'">
      {{ item[layout.data].join(',') }}
    </template>

    <template v-else-if="layout.format === 'date'">
      {{ item[layout.data] }}
    </template>

    <template v-else-if="layout.format === 'booleanTick'">
      <span v-if="item[layout.data]"
        role="image"
        :aria-label="$t('general.true')">✔️</span>
      <span v-if="!item[layout.data]"
        role="image"
        :aria-label="$t('general.false')">❌</span>
    </template>

    <template v-else>
      {{ item[layout.data] }}
    </template>
  </span>
</template>

<script>
export default {
  name: 'DisplayListItem',
  filters: {
    convertHref(input, param) {
      if (!param) return input
      return input.replace(/:([\w]+)/gm, (match, group) => {
        return param[group]
      })
    },
  },
  props: {
    layout: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
    item: {
      type: Object,
      required: true,
      default: () => {
        return {}
      },
    },
  },
  computed: {
    hasValue() {
      return Object.prototype.hasOwnProperty.call(this.item, this.layout.data)
    },
  },
}
</script>
