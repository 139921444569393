<template>
  <div data-name="ip-address-input"
    role="searchbox"
    class="el-row"
    :class="{ inline: inline }">
    <strong v-if="label"
      class="pr-1"
      role="label">{{ $t(label) }}</strong>

    <div class="input-section"
      role="none">
      <el-input :size="inputSize"
        :placeholder="$t(placeholder)"
        :value="inputFieldValue"
        data-demo="192.0.2.1/30"
        :class="invalidateType"
        @input="handleTextChange">
        <template #suffix>
          <span class="tag"
            role="complementary">
            <el-tag v-if="ipV4Status"
              data-name="ipv4-tag"
              type="info"
              size="mini"
              aria-labelledby="address-status"
              role="text">{{ $t('general.ipv4') }}</el-tag>
            <el-tag v-if="ipV6Status"
              data-name="ipv6-tag"
              type="info"
              size="mini"
              aria-labelledby="address-status"
              role="text">{{ $t('general.ipv6') }}</el-tag>
            <el-tag v-if="isNetworkAddress"
              data-name="network-tag"
              type="info"
              size="mini"
              aria-labelledby="address-status"
              role="text">{{ $t('general.network') }}</el-tag>
          </span>
        </template>
        <template v-if="!hideClearBtn"
          #append>
          <el-button size="mini"
            @click="handleClearText">
            <i class="fa fa-times-circle"
              label="Clear filter routes"
              aria-hidden="true" />
          </el-button>
        </template>
      </el-input>

      <template v-if="invalidate && !showFormatError">
        <p data-name="invalidate-error"
          class="alert-message"
          :class="invalidateType"
          role="alert">
          {{ $t(invalidateMessage) }}
        </p>
      </template>

      <template v-if="showFormatError && showErrorMsg">
        <p data-name="format-error"
          class="alert-message error"
          role="alert">
          {{ $t('validations.invalid-network') }}
        </p>
      </template>
    </div>
  </div>
</template>

<script>
import cidrRegex from 'cidr-regex'
import ipRegex from 'ip-regex'
import { debounce } from 'lodash'

export default {
  name: 'NetworkAddressInput',

  props: {
    label: {
      type: String,
      required: false,
      default: () => {
        return ''
      },
    },
    inputSize: {
      validator: value => ['large', 'medium', 'small', 'mini'].includes(value),
      required: false,
      default: () => 'mini',
    },
    inline: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      },
    },
    showErrorMsg: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    placeholder: {
      type: String,
      required: false,
      default: () => {
        return 'ports.address-placeholder'
      },
    },
    initialValue: {
      type: String,
      required: false,
      default: () => {
        return ''
      },
    },
    invalidate: {
      type: Boolean,
      required: false,
      default: () => {
        return false
      },
    },
    invalidateType: {
      type: String,
      required: false,
      default: () => {
        return ''
      },
    },
    invalidateMessage: {
      type: String,
      required: false,
      default: () => {
        return ''
      },
    },
    hideClearBtn: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    optionalSubnetMask: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    existingIpAddresses: {
      type: Array,
      required: false,
      default: () => [],
    },
  },

  data() {
    return {
      showFormatError: false,
      inputFieldValue: '',
    }
  },

  computed: {
    ipAddress() {
      return this.inputFieldValue.split('/')
    },
    isNetworkAddress() {
      return this.inputFieldValue.includes('/')
    },
    isValidSubnetMask() {
      if (!this.isNetworkAddress) return this.optionalSubnetMask
      const [, subnetMask] = this.ipAddress
      const isSubnetMask = subnetMask && subnetMask.includes('.')

      if (!isSubnetMask) {
        return false
      }

      return ipRegex.v4({ exact: true }).test(subnetMask)
    },
    ipV4Status() {
      return ipRegex.v4({ exact: true }).test(this.ipAddress[0])
    },
    ipV6Status() {
      return ipRegex.v6({ exact: true }).test(this.ipAddress[0])
    },
    cidrValid() {
      if (!this.isNetworkAddress) return this.optionalSubnetMask
      return cidrRegex({ exact: true }).test(this.inputFieldValue)
    },
    ipValid() {
      if (!this.inputFieldValue.length) return true
      return (this.ipV4Status || this.ipV6Status) && (this.cidrValid || this.isValidSubnetMask)
    },
    isExistingIpAddress() {
      return this.existingIpAddresses.find(addr => addr.toUpperCase() === this.inputFieldValue.toUpperCase())
    },
  },

  created() {
    this.slowCheckFn = debounce(this.slowValidate, 1500)
    if (this.initialValue) this.handleTextChange(this.initialValue)
  },

  methods: {
    handleClearText() {
      this.inputFieldValue = ''
      this.showFormatError = false
      this.$emit('updateNetAddress', { value: this.inputFieldValue, valid: this.ipValid })
    },
    handleTextChange(value) {
      this.showFormatError = false
      this.inputFieldValue = value
      this.$emit('updateNetAddress', { value: this.inputFieldValue, valid: this.ipValid, validator: this.validateIpAddress })
      this.slowCheckFn()
    },
    slowValidate() {
      if (!this.ipValid) {
        this.showFormatError = true
      } else {
        this.showFormatError = false
      }
    },
    validateIpAddress(rule, value, callback) {
      if (rule.required && !value) {
        callback(this.$t('validations.required', { thing: this.$t('services.ip-address') }))
      } else if (value && this.isExistingIpAddress) {
        callback(this.$t('validations.ip-in-list'))
      } else if (value && !this.ipValid && this.optionalSubnetMask) {
        callback(this.$t('validations.invalid-network'))
      } else if (value && !this.ipValid && !this.optionalSubnetMask) {
        callback(this.$t('validations.not-valid-network'))
      } else if (value && !this.isNetworkAddress && !this.optionalSubnetMask) {
        callback(this.$t('validations.no-subnet'))
      } else {
        callback()
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.inline {
  display: inline-block;

  strong {
    width: 100px;
    align-self: center;
  }
}

.input-section {
  position: relative;
  flex-grow: 1;

  .el-input {
    min-width: 450px;

    // need this override as different el-input sizes distorts the suffix
    .el-input__suffix {
      -webkit-transform: none;
      transform: none;
    }
  }

  .tag {
    float: right;

    .el-tag {
      margin-left: 0.5rem;
    }
  }
}

.alert-message {
  top: 1.5rem;
  position: absolute;
  font-size: 1.3rem;
}

.error {
  color: var(--color-danger);
  .el-input__inner {
    border-color: var(--color-danger);
  }
}
.warning {
  color: var(--warning);
  .el-input__inner {
    border-color: var(--color-warning);
  }
}
.info {
  color: var(--color-primary);
  .el-input__inner {
    border-color: var(--color-primary);
  }
}
</style>
