<template>
  <div class="bgp-connections mt-1 mb-1 pb-1 el-row"
    data-name="bgp-connections"
    role="navigation">
    <div class="el-row"
      role="none">
      <div class="el-col-18"
        role="none">
        <router-link :to="`/edit-connection/${product.productUid}`">
          {{ product.productName }}
        </router-link>
      </div>
      <div class="el-col-6 text-align-right"
        role="none">
        <el-tag type="info"
          size="mini"
          class="mb-1">
          {{ product.productType }} {{ product.productUid | truncateUid }}
        </el-tag>
      </div>
    </div>

    <div class="el-row headings pb-1"
      role="none">
      <div class="el-col-4"
        role="heading">
        {{ $t('general.status') }}
      </div>
      <div class="el-col-8"
        role="heading">
        {{ $t('services.ip-address') }}
      </div>
      <div class="el-col-5"
        role="heading">
        {{ $t('connections.bgp-peer-asn') }}
      </div>
      <div class="el-col-7"
        role="heading" />
    </div>

    <div v-for="connection in cspConnections"
      :key="connection.virtualRouterName"
      class="el-row"
      role="none">
      <template v-for="(iface, idx) in connection.interfaces">
        <div v-if="!iface.bgpConnections"
          :key="`no-conn${idx}`"
          role="status">
          {{ $t('mcr-looking-glass.no-bgp') }}
        </div>

        <div v-for="bgpConnection in iface.bgpConnections"
          :key="bgpConnection.peerIpAddress"
          class="el-row el-row--flex pb-1"
          data-name="bgp-connection-detail"
          role="none">
          <div class="el-col-4"
            role="none">
            <i v-if="connection.bgp_status[bgpConnection.peerIpAddress]"
              class="fa fa-check-circle success"
              aria-hidden="true" />
            <i v-else-if="!connection.bgp_status[bgpConnection.peerIpAddress]"
              class="fa fa-times-circle danger"
              aria-hidden="true" />
            <i v-else
              class="fa fa-check-exclamation-circle warning"
              aria-hidden="true" />
          </div>
          <div class="el-col-8"
            role="none"
            data-name="peer-ip">
            {{ bgpConnection.peerIpAddress }}
          </div>
          <div class="el-col-5"
            role="none"
            data-name="peer-asn">
            {{ bgpConnection.peerAsn }}
          </div>
          <div class="el-col-7 text-align-right"
            role="none">
            <el-button :type="activePeers.includes(bgpConnection.peerIpAddress) ? 'primary' : 'default'"
              size="mini"
              @click="selectPeer(bgpConnection.peerIpAddress)">
              {{ $t('mcr-looking-glass.neighbour') }}
            </el-button>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BGPConnections',

  filters: {
    truncateUid(input) {
      return input.split('-')[0]
    },
  },

  props: {
    product: {
      type: Object,
      required: false,
      default: () => {
        return {}
      },
    },
    activePeers: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },
  },

  computed: {
    cspEnd() {
      return this.product.aEnd.productUid === this.$route.params.mcr ? 'a_csp_connection' : 'b_csp_connection'
    },
    cspConnections() {
      if (Array.isArray(this.product.resources.csp_connection)) {
        return this.product.resources.csp_connection.filter(csp => {
          return csp.connectType === 'VROUTER' && csp.resource_name === this.cspEnd
        })
      }
      return [this.product.resources.csp_connection]
    },
  },

  methods: {
    selectPeer(peer) {
      this.$emit('selectPeer', peer)
    },
  },
}
</script>

<style lang="scss" scoped>
.bgp-connections {
  border-bottom: 1px dotted #ccc;

  h5 {
    font-weight: bold;
  }
  .success {
    color: var(--color-success);
  }
  .warning {
    color: var(--color-warning);
  }
  .danger {
    color: var(--color-danger);
  }

  .headings {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .el-row--flex {
    align-items: center;
  }
}
</style>
