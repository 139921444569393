<template>
  <div data-name="routes-table-filter"
    role="menuitem">
    <strong class="pr-1"
      role="label">{{ label }}</strong>
    <el-button-group>
      <el-button v-for="filter in filterList"
        :key="filter.value"
        :type="filter.value === typeFilter ? 'primary' : 'default'"
        size="mini"
        @click="handleTypeChange(filter)">
        {{ filter.title }}
      </el-button>
    </el-button-group>
  </div>
</template>

<script>
export default {
  name: 'RoutesTableFilter',

  props: {
    typeFilter: {
      type: String,
      required: false,
      default: () => {
        return 'all'
      },
    },
    filterList: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },
    label: {
      type: String,
      required: false,
      default: () => {
        return ''
      },
    },
  },

  data() {
    return {
      activeFilter: this.filterList[0],
    }
  },

  methods: {
    handleTypeChange(filter) {
      this.activeFilter = filter
      this.$emit('updateFilter', { key: 'typeFilter', value: filter.value, column: filter.column })
    },
  },
}
</script>
