<template>
  <div class="filterText el-col-8"
    data-name="text-search-input"
    role="searchbox">
    <el-input size="mini"
      :placeholder="$t('mcr-looking-glass.filter-routes')"
      :value="textFilter"
      @input="handleTextChange">
      <template #append>
        <el-button size="mini"
          @click="clearTextFilter">
          <i class="fa fa-times-circle"
            :label="$t('mcr-looking-glass.clear-filter-routes')"
            aria-hidden="true" />
        </el-button>
      </template>
    </el-input>
  </div>
</template>

<script>
export default {
  name: 'TextSearchInput',

  props: {
    textFilter: {
      type: String,
      required: false,
      default: () => {
        return ''
      },
    },
  },

  methods: {
    handleTextChange(value) {
      this.$emit('updateFilter', { key: 'textFilter', value: value.trim() })
    },
    clearTextFilter() {
      this.handleTextChange('')
    },
  },
}
</script>

<style scoped>
.filterText .el-input {
  vertical-align: baseline;
}
</style>
