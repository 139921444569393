<template>
  <section data-name="route-diagnostics-view"
    class="pb-4 mb-4">
    <div v-if="isLoadingPortsList"
      class="el-row"
      role="progressbar">
      <div class="el-col-24 p-1"
        role="none">
        <div id="loadingText"
          v-loading="true"
          role="status"
          :element-loading-text="$t('services.loading-resources')" />
      </div>
    </div>

    <!-- No service -->
    <div v-if="!isLoadingPortsList && !mcrPortsLive.length"
      class="el-col-24 noService mt-1 pt-4 pb-4 text-align-center"
      role="alert">
      <h2>{{ $t('mcr-looking-glass.no-services') }}</h2>
      <p>{{ $t('mcr-looking-glass.no-services-message') }}</p>
      <p>{{ $t('mcr-looking-glass.contact-support') }}</p>
    </div>

    <!-- Title -->
    <div v-else-if="!isLoadingPortsList && activeMCR"
      class="el-row"
      role="main">
      <header>
        <div class="el-col-24"
          role="none">
          <h2>{{ $t('menu.mcr-looking-glass') }}</h2>
        </div>
      </header>

      <!-- Wrapper so we can be sure of a white background no matter what the whitelabel colour is -->

      <!-- Service heading -->
      <div data-name="service-heading"
        class="el-row pl-2 pt-1"
        role="none">
        <div class="el-col-20"
          role="none">
          <h3>{{ activeMCR.productName }}</h3>
          <el-tag type="info"
            size="mini"
            class="mb-1">
            {{ $t('services.service-id') }} {{ activeMCR.productUid | truncateUid }}
          </el-tag>
        </div>
        <div class="el-col-4 text-align-right"
          role="none">
          <el-button data-name="reload-data-button"
            class="mr-1"
            type="default"
            size="small"
            @click="reloadData">
            {{ $t('general.reload-data') }}
          </el-button>
        </div>
      </div>

      <!-- Main layout -->
      <div class="el-row pl-2 pt-1"
        role="none">
        <!-- Left column -->
        <div class="el-col-md-8 el-col-sm-24 mb-4"
          role="none">
          <el-tabs type="border-card"
            class="tabRegion">
            <el-tab-pane :label="$t('mcr-looking-glass.selection')">
              <!-- MCR Dropdown -->
              <mcr-dropdown-select :mcr-list="mcrPortsLive"
                :active-m-c-r="activeMCR"
                @update="handleSelectMCR" />
              <hr class="mt-1 pt-1">
              <!-- Load -->
              <div v-if="isLoadingMultipleServices"
                v-loading="isLoadingMultipleServices"
                class="mt-2 pt-2"
                :element-loading-text="$t('services.loading-services')"
                element-loading-background="rgba(0, 0, 0, 0.0)"
                role="status" />

              <!-- Sessions list -->
              <bgp-sessions-list v-else
                :service-list="serviceList"
                :active-peers="activeBGPTabs"
                @selectPeer="handleSelectPeer" />
            </el-tab-pane>
          </el-tabs>
        </div>

        <!-- Right column -->
        <div class="pl-2 pr-2 el-col-md-16 el-col-sm-24"
          role="none">
          <el-tabs id="route-tables"
            v-model="activeTab"
            type="border-card"
            class="tabRegion"
            @edit="removeTab">
            <!-- Routes table -->
            <el-tab-pane :label="$t('mcr-looking-glass.routes-table')"
              name="allRoutes">
              <div v-if="isLoadingMultipleServices"
                v-loading="isLoadingMultipleServices"
                class="mt-2 pt-2"
                :element-loading-text="$t('services.loading-services')"
                element-loading-background="rgba(0, 0, 0, 0.0)"
                role="status" />
              <mcr-routes-view v-else
                :product-uid="activeMCR.productUid"
                context="ip" />
            </el-tab-pane>

            <!-- BGP table -->
            <el-tab-pane :label="$t('mcr-looking-glass.bgp-table')"
              name="bgpRoutes">
              <div v-if="isLoadingMultipleServices"
                v-loading="isLoadingMultipleServices"
                class="mt-2 pt-2"
                :element-loading-text="$t('services.loading-services')"
                element-loading-background="rgba(0, 0, 0, 0.0)"
                role="status" />
              <mcr-routes-view v-else
                :product-uid="activeMCR.productUid"
                context="bgp" />
            </el-tab-pane>

            <!-- bgp peers -->
            <el-tab-pane v-for="route in activeBGPTabs"
              :key="route"
              :label="route"
              :name="route"
              closable>
              <div v-if="isLoadingMultipleServices"
                v-loading="isLoadingMultipleServices"
                class="mt-2 pt-2"
                :element-loading-text="$t('services.loading-services')"
                element-loading-background="rgba(0, 0, 0, 0.0)"
                role="status" />
              <peer-routes-view v-else
                :ip-address="route" />
            </el-tab-pane>

            <!-- Question mark -->
            <el-tab-pane class="tooltip"
              name="tooltip"
              disabled>
              <template #label>
                <el-tooltip placement="top"
                  :content="$t('mcr-looking-glass.all-reachable')"
                  :open-delay="500">
                  <i class="fas fa-question-circle color-info popover-info-icon"
                    aria-hidden="true" />
                </el-tooltip>
              </template>
            </el-tab-pane>
          </el-tabs>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MCRDropdownSelect from './MCRDropdownSelect'
import BGPSessionsList from './bgp-sessions/BGPSessionsList'
import PeerRoutesView from './peer-routes/PeerRoutesView'
import MCRRoutesView from './MCRRoutesView'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  name: 'MCRLookingGlassView',

  components: {
    'mcr-dropdown-select': MCRDropdownSelect,
    'bgp-sessions-list': BGPSessionsList,
    'peer-routes-view': PeerRoutesView,
    'mcr-routes-view': MCRRoutesView,
  },

  filters: {
    truncateUid(input) {
      return input.split('-')[0]
    },
  },

  data() {
    return {
      activeMCR: null,
      activeTab: 'allRoutes',
      activeBGPTabs: [],
      maxBGPTabs: 4,
    }
  },

  computed: {
    ...mapState('Products', ['isLoadingPortsList', 'isLoadingMultipleServices', 'serviceList']),
    ...mapGetters('Products', ['mcrPorts', 'mcrPortsLive']),
    enabledVxcs() {
      if (!this.activeMCR) return []
      return this.activeMCR.associatedVxcs
        .filter(vxc => {
          return vxc.provisioningStatus !== this.G_PROVISIONING_DECOMMISSIONED
        })
        .map(vxc => {
          return vxc.productUid
        })
    },
  },

  created() {
    window.mpApp.$on('changeContext', value => { if (value) this.getPorts() })
    this.getPorts()
  },

  destroyed() {
    this.clearProductsList()
    this.mcrDestroyedCleanup()

    window.mpApp.$off('changeContext')
  },

  methods: {
    ...mapActions('Products', ['fetchPorts', 'fetchMultipleServices', 'clearProductsList']),
    ...mapActions('MegaportCloudRouter', ['fetchRoutes', 'fetchBGPNeighbourRoutes', 'clearRoutes', 'mcrDestroyedCleanup']),
    async getPorts() {
      await this.fetchPorts()
      const service = this.findServiceByUid(this.$route.params.mcr) || this.mcrPortsLive[0]
      if (service) this.handleSelectMCR(service)
    },
    handleSelectMCR(value) {
      this.activeBGPTabs = []
      this.activeTab = 'allRoutes'
      this.activeMCR = value
      this.fetchAllSessions()
      this.updateRouter({ mcr: value.productUid })
    },
    fetchAllSessions() {
      this.clearProductsList()
      this.clearRoutes()
      this.fetchMultipleServices({ productUids: this.enabledVxcs })
    },
    handleSelectPeer(peer) {
      if (!this.activeBGPTabs.includes(peer)) {
        this.manageTabs(peer)
      }
      this.activeTab = peer
    },
    updateRouter(productUidObj) {
      this.$router.replace(
        {
          name: 'McrLookingGlass',
          params: {
            ...productUidObj,
          },
        },
        () => {
          // empty function is intentional
        }
      )
    },
    async reloadData() {
      this.clearRoutes()
      this.activeTab = 'allRoutes'
      await this.fetchPorts()
      this.activeBGPTabs.forEach(ipAddress => {
        this.fetchBGPNeighbourRoutes({ productUid: this.activeMCR.productUid, ipAddress: ipAddress })
      })
      this.fetchRoutes('ip')
      this.fetchRoutes('bgp')
    },
    removeTab(target) {
      this.activeBGPTabs = this.activeBGPTabs.filter(ipAddress => ipAddress !== target)
      if (this.activeTab === target) this.activeTab = 'allRoutes'
    },
    manageTabs(peer) {
      if (this.activeBGPTabs.length >= this.maxBGPTabs) this.activeBGPTabs.shift()
      this.activeBGPTabs.push(peer)
    },
    findServiceByUid(uid) {
      return this.mcrPortsLive.find(service => {
        return service.productUid === uid
      })
    },
  },
}
</script>

<style lang="scss" scoped>
#loadingText {
  padding-top: 25%;
}

header {
  background-color: var(--mp-sub-header-background-color);
  height: 40px;
  padding: 2rem;

  h2 {
    margin-bottom: 2rem;
    font-weight: 300;
  }
}

.tabRegion {
  border: none;
  box-shadow: none;
  border-radius: 8px;
}

.content-panel {
  background-color: var(--color-white);
  border: 1px solid var(--card-border-color);
  border-radius: var(--border-radius-base);
  margin: 1rem;
  min-width: 300px;
}
</style>

<style lang="scss">
.tabRegion {
  .el-tabs__header {
    border-bottom: 1px solid #e4e7ed;
  }
  .is-active {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.4);
  }
  .el-tabs__nav {
    width: 100%;
    position: relative;
    #tab-tooltip {
      position: absolute;
      right: 0;
    }
  }
}
</style>
