<template>
  <section data-name="bgp-sessions-list"
    class="mt-1 el-row">
    <div class="el-row pt-1"
      role="none">
      <div class="el-col-10"
        role="none">
        <h4>
          {{ $t('mcr-looking-glass.bgp-sessions') }}
          <el-tooltip placement="top"
            :content="$t('mcr-looking-glass.bgp-sessions-tooltip')"
            :open-delay="500">
            <i class="fas fa-question-circle color-info popover-info-icon"
              aria-hidden="true" />
          </el-tooltip>
        </h4>
      </div>

      <div class="el-col-14"
        role="none">
        <el-input size="mini"
          :placeholder="$t('mcr-looking-glass.filter-sessions')"
          :value="bgpSearchText"
          @input="handleFilterText">
          <template #append>
            <el-button size="mini"
              @click="clearTextFilter">
              <i class="fa fa-times-circle"
                label="Clear filter routes"
                aria-hidden="true" />
            </el-button>
          </template>
        </el-input>
      </div>
    </div>
    <div class="el-row"
      role="none">
      <div class="el-col-24 pt-1"
        role="none">
        <p v-if="!serviceList.length"
          role="alert">
          {{ $t('mcr-looking-glass.no-bgp-sessions') }}
        </p>
        <bgp-connections v-for="product in filteredServices"
          :key="product.productUid"
          :product="product"
          :active-peers="activePeers"
          :filter="bgpSearchText"
          @selectPeer="setActivePeer" />
      </div>
    </div>
  </section>
</template>

<script>
import BGPConnections from './BGPConnections'

export default {
  name: 'BGPSessionsList',
  components: {
    'bgp-connections': BGPConnections,
  },
  props: {
    serviceList: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },
    activePeers: {
      type: Array,
      required: false,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      bgpSearchText: '',
      activePeer: '',
    }
  },
  computed: {
    filteredServices() {
      return this.serviceList.filter(service => {
        return JSON.stringify(Object.values(service))
          .toLowerCase()
          .includes(this.bgpSearchText.toLowerCase())
      })
    },
  },
  methods: {
    setActivePeer(peer) {
      this.activePeer = peer
      this.$emit('selectPeer', peer)
    },
    handleFilterText(value) {
      this.bgpSearchText = value
    },
    clearTextFilter() {
      this.bgpSearchText = ''
    },
  },
}
</script>

<style scoped>
h4 {
  font-size: 1.5rem;
  font-weight: bold;
}
</style>
