<template>
  <section data-name="routes-table">
    <table class="el-row pt-1"
      :aria-describedby="$t('mcr-looking-glass.routes-table')">
      <thead>
        <tr>
          <th v-if="collapseRow.length"
            scope="col"
            width="10px">
            <span class="sr-only"
              role="note">{{ $t('mcr-looking-glass.toggle-details') }}</span>
          </th>
          <template v-for="layout in schema.tableItems">
            <th v-if="layout.sortable"
              :key="layout.title"
              scope="col"
              :width="layout.width ? `${layout.width}%` : ''"
              class="sortable"
              role="button"
              :aria-label="$t('mcr-looking-glass.sort-by-thing', { thing: layout.title })"
              :data-value="layout.id"
              @click="handleUpdateSortOrder">
              {{ $t(layout.title) }}
              <template v-if="layout.tooltip">
                <el-tooltip placement="top"
                  :content="$t(layout.tooltip)"
                  :open-delay="500">
                  <i class="fas fa-question-circle color-info popover-info-icon"
                    aria-hidden="true" />
                </el-tooltip>
              </template>
              <span v-if="layout.sortable"
                class="pl-1"
                role="none">
                <table-sort-buttons v-if="sortBy === layout.data"
                  :direction="sortDirection" />
                <table-sort-buttons v-else />
              </span>
            </th>
            <th v-else
              :key="layout.id"
              scope="col"
              :width="'${layout.width}%'">
              {{ $t(layout.title) }}
            </th>
          </template>
        </tr>
      </thead>

      <tbody>
        <template v-for="(route, idx) in routesByPage">
          <tr :id="`route-${idx}`"
            :key="`row-${idx}`"
            :aria-owns="`collapseRow-${idx}`">
            <td v-if="collapseRow.length">
              <button class="el-button el-button--mini is-circle toggle"
                aria-expanded="false"
                @click.stop="toggleRow($event, `collapseRow-${idx}`)">
                <span data-name="expand"
                  class="el-icon-arrow-down"
                  role="option"
                  :aria-label="$t('general.expand')" />
                <span data-name="collapse"
                  class="el-icon-arrow-up hide"
                  role="option"
                  :aria-label="$t('general.collapse')" />
              </button>
            </td>

            <td v-for="layout in schema.tableItems"
              :key="layout.id"
              :class="{ alignRight: layout.align === 'right', alignRightSortable: layout.align === 'right' && layout.sortable }">
              <display-list-item v-if="!layout.nested"
                :item="route"
                :layout="layout" />

              <el-button v-if="layout.copy"
                size="mini"
                type="default"
                class="copyIcon"
                :title="$t('general.copy-thing-to-clipboard', { thing: layout.title })"
                @click="copyToClipboard(route[layout.data])">
                <img src="@/assets/copy.png"
                  :alt="$t('images.copy-icon')">
              </el-button>

              <template v-if="layout.nested && route[layout.parent]">
                <display-list-item :key="layout.id"
                  :item="route[layout.parent]"
                  :layout="layout" />
              </template>
            </td>
          </tr>
          <tr v-if="collapseRow.length"
            :id="`collapseRow-${idx}`"
            :key="`row-collapse-${idx}`"
            :ref="`collapseRow-${idx}`"
            class="collapseRow hide"
            :aria-activedescendant="`route-${idx}`">
            <td />
            <td :colspan="schema.tableItems.length">
              <ul>
                <li v-for="layout in collapseRow"
                  :key="layout.id">
                  <strong>{{ layout.title }}:</strong>
                  <display-list-item :key="layout.id"
                    :item="route"
                    :layout="layout" />
                </li>
              </ul>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <div class="pagination el-row pt-1"
      role="none">
      <p role="none">
        {{ $t('mcr-looking-glass.total-routes') }}: {{ tableItems.length }}
      </p>
      <el-pagination background
        layout="prev, pager, next"
        :hide-on-single-page="true"
        :total="tableItems.length"
        :current-page.sync="currentPage"
        :page-size.sync="itemsPerPage"
        @current-change="handleCurrentChange" />
      <el-select v-model="itemsPerPage"
        size="small"
        class="ml-1"
        @change="handlePaginationSettings">
        <el-option v-for="item in paginationOptions"
          :key="item.value"
          :label="$t(item.label)"
          :value="item.value" />
      </el-select>
    </div>
    <hr>
  </section>
</template>

<script>
import TableSortButtons from './TableSortButtons'
import DisplayListItem from './DisplayListItem'
import { copyToClipboard } from '@/helpers'

export default {
  name: 'RoutesTable',

  components: {
    'table-sort-buttons': TableSortButtons,
    'display-list-item': DisplayListItem,
  },

  props: {
    tableItems: {
      type: Array,
      required: true,
      default: () => {
        return []
      },
    },
    schema: {
      type: Object,
      required: true,
    },
    sortBy: {
      type: String,
      required: false,
      default: () => {
        return ''
      },
    },
    sortDirection: {
      type: Number,
      required: false,
      default: () => {
        return 0
      },
    },
  },

  data() {
    return {
      itemsPerPage: 20,
      currentPage: 1,
      paginationOptions: [
        { value: 20, label: this.$t('general.items-per-page', { count: 20 }) },
        { value: 40, label: this.$t('general.items-per-page', { count: 40 }) },
        { value: 80, label: this.$t('general.items-per-page', { count: 80 }) },
        { value: 100, label: this.$t('general.items-per-page', { count: 100 }) },
        { value: 100000, label: this.$t('general.show-all') },
      ],
    }
  },

  computed: {
    collapseRow() {
      return this.schema.collapseTableItems || []
    },
    routesByPage() {
      const indexStart = (this.currentPage - 1) * this.itemsPerPage
      return this.tableItems.slice(indexStart, indexStart + this.itemsPerPage)
    },
  },

  created() {
    this.itemsPerPage = Number.parseInt(window.localStorage.getItem('mcrLookingGlassPagination')) || 20
  },

  methods: {
    copyToClipboard,
    handleUpdateSortOrder(event) {
      const target = event.target.dataset.value
      this.$emit('updateSortOrder', target)
    },
    toggleRow(event, rowId) {
      if (this.$refs[rowId][0].classList.contains('hide')) {
        event.target.setAttribute('aria-expanded', 'true')
        this.$refs[rowId][0].classList.remove('hide')
        event.target.firstChild.classList.add('hide')
        event.target.lastChild.classList.remove('hide')
      } else {
        event.target.setAttribute('aria-expanded', 'false')
        this.$refs[rowId][0].classList.add('hide')
        event.target.firstChild.classList.remove('hide')
        event.target.lastChild.classList.add('hide')
      }
    },
    handleCurrentChange(value) {
      this.currentPage = value
    },
    handlePaginationSettings(value) {
      window.localStorage.setItem('mcrLookingGlassPagination', value)
    },
  },
}
</script>

<style lang="scss" scoped>
table {
  width: 100%;
  border-collapse: collapse;
  tbody {
    tr {
      height: 40px;
      border-top: 1px solid #f2f2f2;
      &:hover {
        background-color: #ededed;
      }

      &.collapseRow {
        border-top: none;
        &:hover {
          background-color: transparent;
        }
        &.hide {
          display: none;
        }
      }
    }
  }
  th {
    text-align: left;
    &.sortable {
      cursor: pointer;
    }
    span {
      pointer-events: none;
    }
  }
  th,
  td {
    padding: 0.75rem 0.5rem;

    &.alignRight {
      text-align: right;
    }

    &.alignRightSortable {
      padding-right: 30px;
    }
  }
}
ul {
  margin: 0;
  padding: 0;
  li {
    list-style: none;
    strong {
      width: 200px;
      padding-right: 1rem;
    }
  }
}

.toggle {
  span {
    pointer-events: none;
    margin: 0;
    &.hide {
      display: none;
    }
  }
}

.pagination {
  display: flex;
  align-items: center;
  p {
    font-weight: bold;
  }
}

.copyIcon {
  padding: 0;
  margin-left: 1rem;
  border: none;
}
.copyIcon img {
  width: 10px;
  height: 10px;
  opacity: 0.5;
  mix-blend-mode: darken;

  &:hover {
    opacity: 1;
  }
}
</style>
